import { Grid } from '@mui/material';
import { red } from '@mui/material/colors';
import loadApi from 'api/load-api';
import ButtonDownload from 'components/ButtonDownload';
import CollapsibleTableLoadLoad from 'components/CollapsibleTableLoadLoad';
import GenericDropdown from 'components/GenericDropdown';
import GenericTableBlueHeader from 'components/GenericTableBlueHeader';
import LoadingContainerScreen from 'components/loading-container-screen/loading-container-screen';
import MainPageTitle from 'components/MainPageTitle';
import Table2HeadersLines from 'components/Table2HeadersLines';
import TableMultipleHeaders from 'components/TableMultipleHeaders';
import { LoadLoad, LoadOptionsProduct, LoadOptionsSafraProductsResponse, Plates, PlatesWeight, PlotType, ProductType } from 'model/load';
import { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import LoadService from 'services/load-service';
import styled from 'styled-components';
import StyledFilterContainer from 'styled-components/StyledFilterContainer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';

const LoadByLoad = ({ t }: WithTranslation) => {
  const [productsListsByYears, setProductsListsByYears] = useState<LoadOptionsSafraProductsResponse[]>([]);
  const [chosenYear, setChosenYear] = useState<string>('');
  const [productsList, setProductsList] = useState<LoadOptionsProduct[]>([]);
  const [chosenProduct, setChosenProduct] = useState<string>('');
  const [platesList, setPlatesList] = useState<Plates[]>([]);
  const [chosenPlate, setChosenPlate] = useState<string>('');
  const [loadLoad, setLoadLoad] = useState<LoadLoad[]>([]);

  const [platesInfo, setPlatesInfo] = useState<PlatesWeight[]>([]);

  const [summaryProduct, setsummaryProduct] = useState<ProductType[]>([]);
  const [summaryPlot, setSummaryPlot] = useState<PlotType[]>([]);

  const [reportLoadLoad, setReportLoadLoad] = useState<string>();
  const [allPlates] = useState<Plates>({
    CD_PLACA: '0',
    CD_PLACA_TEXT: 'TODOS',
  });

  const [isResetProducts, setIsResetProducts] = useState(false);
  const [isResetPlates, setIsResetPlates] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNoDataFound, setIsNoDataFound] = useState(false);

  const tableHeaderSummaryPlate = [t('table.plate'), t('table.grossWeight'), t('table.netWeight')];

  const getUserRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const getDataOptions = async (registration: number) => {
    await LoadService.getHarvestAndProducts(registration.toString()).then(response => {
      console.log('response: ' + response);
      if (!response?.length) {
        setIsNoDataFound(true);
      } else {
        setProductsListsByYears(response);
        setIsNoDataFound(false);
      }
    });
  };

  useEffect(() => {
    getDataOptions(getUserRegistration);
  }, []);

  useEffect(() => {
    if (chosenYear) {
      setChosenProduct('');
      setIsResetProducts(true);
      setProductsList(productsListsByYears.find(item => item.CD_SAFRA_TEXT === chosenYear)?.PRODUTOS ?? []);
    }
  }, [chosenYear]);

  useEffect(() => {
    setPlatesList([allPlates]);
    if (chosenProduct) {
      setChosenPlate('');
      setPlatesList(existingPlatesList => [
        ...existingPlatesList,
        ...(productsList.find(item => item.DS_PRODUTO === chosenProduct)?.PLACAS ?? []),
      ]);
      setIsResetPlates(true);
    }
  }, [chosenProduct]);

  const getInfo = async (registration: number) => {
    if (chosenProduct && chosenYear && chosenPlate) {
      setIsLoading(true);
      const chosenHarvestCD = productsListsByYears.find(item => item.CD_SAFRA_TEXT === chosenYear)?.CD_SAFRA ?? '';
      const chosenProductCD = productsList.find(item => item.DS_PRODUTO === chosenProduct)?.CD_PRODUTO.toString() ?? '';
      const platesListCD = platesList.find(item => item.CD_PLACA_TEXT === chosenPlate)?.CD_PLACA ?? '0';
      await setReportLoadLoad(loadApi.getLoadLoadReport(registration.toString(), chosenHarvestCD, chosenProductCD, platesListCD));
      await LoadService.getInfo(registration.toString(), chosenHarvestCD, chosenProductCD, platesListCD).then(response => {
        setLoadLoad(response.CargaCarga);
        setPlatesInfo(
          response.ResumoPlaca.map(item => {
            return {
              plate: item.CD_PLACA,
              grossWeight: item.QT_PESO_BRUTO,
              netWeight: item.QT_PESO_LIQUIDO,
            };
          })
        );
        setsummaryProduct(response.ResumoProdutos);
        setSummaryPlot(response.ResumoTalhao);
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    if (isResetProducts) {
      setIsResetProducts(!isResetProducts);
    }
  }, [isResetProducts]);

  useEffect(() => {
    if (isResetPlates) {
      setIsResetPlates(!isResetPlates);
    }
  }, [isResetPlates]);

  useEffect(() => {
    if (chosenProduct && chosenYear && chosenPlate) {
      console.log(chosenProduct + ' - ' + chosenYear + ' - ' + chosenPlate);
      getInfo(getUserRegistration);
    } else {
      setLoadLoad([]);
      setsummaryProduct([]);
      setPlatesInfo([]);
      setSummaryPlot([]);
      setChosenPlate('');
    }
  }, [chosenPlate]);

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('agricultural.loadLoad')} />
      </StyledPageTitleContainer>

      <StyledFiltersContainer>
        <StyledFilterContainer filterWidth={300}>
          <GenericDropdown
            label={t('agricultural.harvest')}
            placeholder={t('agricultural.selectHarvest')}
            listShowDropdown={productsListsByYears.map(item => item.CD_SAFRA_TEXT)}
            getSelectedValue={value => setChosenYear(value)}
          />
        </StyledFilterContainer>
        <StyledFilterContainer filterWidth={300}>
          <GenericDropdown
            label={t('agricultural.product')}
            placeholder={t('agricultural.selectProduct')}
            listShowDropdown={productsList.map(item => item.DS_PRODUTO)}
            getSelectedValue={value => setChosenProduct(value)}
            isResetValue={isResetProducts}
          />
        </StyledFilterContainer>
        <StyledFilterContainer filterWidth={300}>
          <GenericDropdown
            label={t('agricultural.plate')}
            placeholder={t('agricultural.selectPlate')}
            listShowDropdown={platesList.map(item => item.CD_PLACA_TEXT)}
            getSelectedValue={value => setChosenPlate(value)}
            isResetValue={isResetPlates}
          />
        </StyledFilterContainer>
      </StyledFiltersContainer>
      {isNoDataFound && (
        <StyledPageTitleContainer margin={'25px 0 14px 0'}>
          <MainPageTitle fontSize={16} style={{ color: 'red' }} text={t('agricultural.noDataFound')} />
        </StyledPageTitleContainer>
      )}
      <LoadingContainerScreen isLoading={isLoading}>
        {reportLoadLoad && <ButtonDownload href={reportLoadLoad} style={{ width: '174px', marginTop: '5px' }} />}
        {loadLoad.map((item, index) => {
          const arrayOfValues = item.DESCONTOS.map(discountItem => {
            return {
              fieldIdentification: discountItem.DS_TIPO_DESCONTO,
              value: `${discountItem.TX_APLICADA.toLocaleString('pt-BR')}    (${discountItem.PC_DESCONTO.toLocaleString(
                'pt-BR'
              )}%)  ${discountItem.VL_DESCONTADO.toLocaleString('pt-BR')}`,
            };
          });
          return (
            <CollapsibleTableLoadLoad
              key={index}
              headerItems={[`${item.DS_PRODUTO_CL} ${item.DT_HORA_ENTRADA}`, `nfec:${item.NR_NFEC} ${t('table.plate')}: ${item.CD_PLACA}`]}
              analysisDate=""
              tableItems={[
                { fieldIdentification: t('agricultural.romanium'), value: item.NR_ROMANEIO },
                { fieldIdentification: t('agricultural.modality'), value: item.DS_TIPO_ROMANEIO },
                { fieldIdentification: t('agricultural.branch'), value: item.NM_REDUZIDO_FILIAL },
                { fieldIdentification: t('agricultural.plot'), value: item.NR_TALHAO },
                { fieldIdentification: t('agricultural.grossWeight'), value: item.QT_PESO_BRUTO },
                ...arrayOfValues,
                { fieldIdentification: t('agricultural.finalProduct'), value: item.QT_PESO_LIQUIDO },
              ]}
              isInvertLineColor
              isDontHaveMaximumWidth
              style={{ margin: '25px 0' }}
            />
          );
        })}
        <Grid container spacing={4}>
          {summaryProduct.map((item, index) => {
            const arrayOfValues = item.DESCONTOS.map(discountItem => {
              return {
                fieldIdentification: discountItem.DS_TIPO_DESCONTO,
                value: `${discountItem.VL_DESCONTADO.toLocaleString('pt-BR')}`,
              };
            });
            return (
              <Grid key={index} item md={12} lg={6}>
                <Table2HeadersLines
                  isDontHaveMaximumWidth
                  arrayOfHeaders={[
                    [t('agricultural.summaryByProduct'), ''],
                    [item.DS_PRODUTO_RE, ''],
                  ]}
                  arrayOfData={[
                    [t('agricultural.loadQuantity'), item.QT_CARGAS],
                    [t('agricultural.grossWeight'), item.QT_PESO_BRUTO.toLocaleString('pt-BR')],
                    ...arrayOfValues.map(itemValue => Object.values(itemValue)),
                    [t('agricultural.finalProduct'), item.QT_PESO_LIQUIDO.toLocaleString('pt-BR')],
                  ]}
                />
              </Grid>
            );
          })}
          {summaryPlot.map((item, index) => {
            const arrayOfValues = item.DESCONTOS.map(discountItem => {
              return {
                fieldIdentification: discountItem.DS_TIPO_DESCONTO,
                value: `${discountItem.VL_DESCONTADO.toLocaleString('pt-BR')}`,
              };
            });
            return (
              <Grid key={index} item md={12} lg={6}>
                <Table2HeadersLines
                  isDontHaveMaximumWidth
                  arrayOfHeaders={[
                    [t('agricultural.summaryByField'), ''],
                    [item.NR_TALHAO, ''],
                  ]}
                  arrayOfData={[
                    [t('agricultural.loadQuantity'), item.QT_CARGAS],
                    [t('agricultural.grossWeight'), item.QT_PESO_BRUTO.toLocaleString('pt-BR')],
                    ...arrayOfValues.map(itemValue => Object.values(itemValue)),
                    [t('agricultural.finalProduct'), item.QT_PESO_LIQUIDO.toLocaleString('pt-BR')],
                  ]}
                />
              </Grid>
            );
          })}
          {platesInfo?.length > 0 && (
            <Grid item md={12} lg={6}>
              <GenericTableBlueHeader
                headerItems={tableHeaderSummaryPlate}
                tableItems={platesInfo.map(item => {
                  return {
                    plate: item.plate,
                    grossWeight: item.grossWeight.toLocaleString('pt-BR'),
                    netWeight: item.netWeight.toLocaleString('pt-BR'),
                  };
                })}
                isInvertLineColor
                isDontHaveMaximumWidth
              />
            </Grid>
          )}
        </Grid>
      </LoadingContainerScreen>
    </>
  );
};

const StyledFiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
`;

export default withTranslation()(LoadByLoad);
